var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Law" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("TransitionSlide", [
            _c("div", { staticClass: "text" }, [_c("h1", [_vm._v("Ley")])])
          ])
        ],
        1
      ),
      _c("TransitionSlide", { attrs: { side: "bottom" } }, [
        _c("div", { staticClass: "content" }, [
          _c("div", [
            _c("p", [
              _vm._v(" La "),
              _c("b", [_vm._v("Ley 11/2018")]),
              _vm._v(" transpone la "),
              _c("b", [
                _vm._v(
                  "Directiva 2014/95/UE de información no financiera y sobre diversidad"
                )
              ]),
              _vm._v(" a la legislación española. ")
            ]),
            _c("p", [
              _vm._v(
                " Esta Ley tiene el objetivo de “identificar riesgos para mejorar la sostenibilidad y aumentar la confianza de los inversores, los consumidores y la sociedad en general y para ello incrementa la divulgación de información no financiera, como pueden ser los factores sociales y medioambientales”(Ley 11/2018, pág.129833). "
              )
            ])
          ]),
          _c("div", { staticClass: "panels" }, [
            _c("div", { staticClass: "panel" }, [
              _c("h3", [_vm._v("Temáticas a reportar")]),
              _c("ul", [
                _c("li", [_vm._v("Información medioambiental")]),
                _c("li", [
                  _vm._v("Cuestiones sociales y relativas al personal")
                ]),
                _c("li", [_vm._v("Respeto de los derechos humanos")]),
                _c("li", [_vm._v("Lucha contra la corrupción y el soborno")]),
                _c("li", [_vm._v("Sociedad")])
              ])
            ]),
            _c("div", { staticClass: "panel" }, [
              _c("h3", [_vm._v("Empresas afectadas")]),
              _c("p", [
                _vm._v(
                  "Las empresas con más de 250 empleados, que cumplan con, al menos, uno de estos dos requisitos:"
                )
              ]),
              _c("ul", [
                _c("li", [_vm._v("Sean entidades de interés público")]),
                _c("li", [
                  _vm._v(
                    " Sean entidades que, a fecha de cierre, y durante, al menos, dos ejercicios consecutivos, superen: "
                  ),
                  _c("ul", [
                    _c("li", [_vm._v("Activo > 20,000,000€")]),
                    _c("li", [_vm._v("Cifra de negocios > 40,000,000€")])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }