<template>
  <div class="Law">
    <div class="header">
      <TransitionSlide>
        <div class="text">
          <h1>Ley</h1>
        </div>
      </TransitionSlide>
    </div>

    <TransitionSlide side="bottom">
      <div class="content">
        <div>
          <p>
            La <b>Ley 11/2018</b> transpone la <b>Directiva 2014/95/UE de información no financiera y sobre diversidad</b> a la legislación española.
          </p>

          <p>
            Esta Ley tiene el objetivo de “identificar riesgos para mejorar la sostenibilidad y aumentar la confianza de los inversores, los consumidores y la sociedad en general y para ello incrementa la divulgación de información no financiera, como pueden ser los factores sociales y medioambientales”(Ley 11/2018, pág.129833).
          </p>
        </div>

        <div class="panels">
          <div class="panel">
            <h3>Temáticas a reportar</h3>

            <ul>
              <li>Información medioambiental</li>
              <li>Cuestiones sociales y relativas al personal</li>
              <li>Respeto de los derechos humanos</li>
              <li>Lucha contra la corrupción y el soborno</li>
              <li>Sociedad</li>
            </ul>
          </div>

          <div class="panel">
            <h3>Empresas afectadas</h3>

            <p>Las empresas con más de 250 empleados, que cumplan con, al menos, uno de estos dos requisitos:</p>
            <ul>
              <li>Sean entidades de interés público</li>
              <li>
                Sean entidades que, a fecha de cierre, y durante, al menos, dos ejercicios consecutivos, superen:
                <ul>
                  <li>Activo > 20,000,000€</li>
                  <li>Cifra de negocios > 40,000,000€</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <!-- <TableCustom
          :value="value"
          v-bind="table"
          @input="answerSelected"
        /> -->
      </div>
    </TransitionSlide>
  </div>
</template>

<script>
import TransitionSlide from '@/transitions/TransitionSlide';

// import TableCustom from '@/components/questions/TableCustom';

export default {
  components: {
    TransitionSlide,
    // TableCustom,
  },

  data() {
    return {
      table: {
        head: ['', 'H1', 'H2', 'h3', 'h4', 'h5'],
        body: [
          {
            number: 2,
            main: true,
            fixed: false,
            items: [
              { type: 'statement', attributes: { type: 'simple' } },
              { type: 'input', attributes: { type: 'text' } },
              { type: 'input', attributes: { type: 'number', units: '%' } },
              { type: 'select', attributes: { choices: [1, 2, 3, 4] } },
              { type: 'choice', attributes: { choices: [1, 2, 3, 4] } },
              { type: 'choices', attributes: { choices: [1, 2, 3, 4] } },
            ],
          },
          {
            number: 1,
            main: true,
            fixed: true,
            items: [
              { type: 'statement', attributes: { type: 'simple' } },
              { type: 'statement', attributes: { type: 'simple' } },
              { type: 'statement', attributes: { type: 'simple' } },
              { type: 'statement', attributes: { type: 'simple' } },
              { type: 'statement', attributes: { type: 'simple' } },
              { type: 'statement', attributes: { type: 'simple' } },
            ],
          },
        ],
      },
      value: {
        'TABLA1.R0.C1': 12,
        'TABLA1.R1.C1': 3,
        'TABLA1.H5': 'Mi columna',
        'TABLA1.R0.C3': 2,
        'TABLA1.R3.C0': 'asdf',
        'TABLA1.R3.C1': 3,
        'TABLA1.R3.C2': 'blabla',
      },
    };
  },

  methods: {
    answerSelected(value) {
      this.value = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.Law {
  .header {
    padding-top: $theHeaderSpacing;
    background-image: url('~@/assets/images/f_home.jpg');
    background-size: cover;
    height: 200px;
    .text {
      margin: 3rem auto 3rem;
      max-width: 1200px;
      padding: 0 2rem 2rem;
      h1 {
        color: white;
        text-transform: uppercase;
        font-size: $font-size-3xl;
        font-weight: bold;
      }
    }
  }

  .content {
    max-width: 1200px;
    margin: 1rem auto;
    padding: 2rem;
    display: grid;
    gap: $spacing-s;

    b{
      font-weight: 500;
      color: $color-primary-darker;
    }

    p{
      margin-bottom: $spacing-s;
    }

    h3{
      font-size: $font-size-l;
      font-weight: 500;
      text-transform: uppercase;
      border-bottom: 1px solid;
      margin-bottom: $spacing-s;
    }

    .panels{
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(400px,1fr));
      gap: $spacing-m;

      .panel{
        background: $color-neutral-light;
        padding: $spacing-s;
      }
    }

    ul {
      padding: $spacing-xs;
      padding-left: $spacing-xl;
      list-style: initial;

      li {
        margin-bottom: $spacing-xs;


        &::marker {
          color: $color-primary;
        }
      }
    }

  }
}
</style>
